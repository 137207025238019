.download-page {
  &__search-form {
    padding: 30px 20px 20px 40px;

    .ant-form-item {
      max-width: 300px;
    }

    .form-item__date {
      max-width: 400px;
    }

    .form-item__action {
      max-width: 100%;
      button {
        margin-left: 7px;
      }
    }
  }
  &__download-all-btn {
    text-align: right;
    padding-right: 20px;
  }
  &__table {
    thead {
      th {
        font-weight: bold;
      }
    }
    .custom-spinner {
      display: inline-block;
      width: 60px;
      margin-top: 30px;
      height: 60px;
      border: 6px solid #1890ff;
      border-top-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
      border-radius: 50%;
      animation: spin 1s linear infinite;
    }

    .spinner-content {
      margin-top: 30px;
      color: #1890ff;
    }
  }

  .ant-modal-body {
    .ant-table-thead {
      position: sticky;
      top: 0;
      background-color: white;
      z-index: 1;
  
      th {
        font-weight: bold;
      }
    }
  }
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.ant-modal-body {
  .ant-table-thead {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;

    th {
      font-weight: bold;
    }
  }
}

.ant-modal-title {
  font-weight: bold;
}