.upload-button-container {
    margin-top: 13px;

}

.upload-json-container {
    margin-top: 12px;
    padding-left: 16px;

    .ant-upload-list-item-card-actions-btn {
        opacity: 1;
    }
}