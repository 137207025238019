@import 'src/assets/scss/responsive.scss';
@import 'src/assets/scss/variable.scss';

.page-header-wrapper {
  background: $color-header-black;
}

.default-page-header {
  font-style: normal;
  padding: 12px 26px;

  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  .ant-page-header-heading-left {
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-page-header-content {
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ant-page-header-heading-sub-title {
    font-weight: 400;
    font-size: 16px;
    color: $color-pure-black;
    margin-top: 12px;
  }
}